import React, { useState, useEffect } from "react";
import { Carousel } from "react-bootstrap";

const HeroImage = () => {
  const [index, setIndex] = useState(0);
  const [images, setImages] = useState([
    "/backgrounds/bg1.jpg",
    "/backgrounds/bg2.jpg",
    "/backgrounds/bg3.jpg",
    "/backgrounds/bg4.jpg",
    "/backgrounds/bg5.jpg",
  ]);

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);
    return () => clearInterval(interval);
  }, [images.length]);

  return (
    <Carousel>
      {images.map((image, i) => (
        <Carousel.Item key={i}>
          <img
            className="d-block w-100"
            src={process.env.PUBLIC_URL + image}
            alt={`Slide ${i}`}
            style={{ height: "auto", maxWidth: "100%" }}
          />
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

export default HeroImage;