import React from 'react';
import './Details.css';

const Details = () => (
  <div style={{ backgroundImage: `url(/backgrounds/detail-bg.jpg)`, backgroundSize: "cover", backgroundPosition: "center", height: "100vh"}}>
    <section className='detail-container'>
      <div className='max-text'>
        <h1>At Shamrock Paint Works the details matter! </h1>
        <ul>
          <li >I am a one-man band, which means that all pieces are meticulously prepped by the same hands that will be refinishing them, meaning that no shortcuts are taken.</li>
          <li>All logos are painted on, which allows you to be flexible with colours and techniques. All logos are also redrawn to perfectly match the factory decals, which means that you won't see any strange fonts on our pieces. </li>
          <li>I have always stayed in tune with the times and what's current, as well as being heavily inspired by vintage road bikes and racing cars. We can work together creatively to come up with your dream bike.</li>
          <li>I have been a mechanic for 11 years, 5 of which were in a high-end bike shop where I specialized in wheel build, suspension and bespoke builds. We can make it all happen in one process reducing the time away from your machine. </li>
          <li>New stuff is expensive, but the tech doesn't change drastically from year to year. Refinish your current equipment to a better-than-new level at a fraction of the price. It's also better for the environment!</li>
        </ul>
      </div>
    </section>
  </div>
)

export default Details
