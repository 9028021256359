import React, { useRef } from 'react';
import './Contact.css';

import { Element } from 'react-scroll';

const Contact = () => {
  const contactRef = useRef(null);

  return (
    <Element id="contact" offset={100}>
      <div style={{ backgroundImage: `url(/backgrounds/contact-bg.jpg)`, backgroundSize: "cover", backgroundPosition: "center", height: "100vh"}}>
        <section className='contact-container'>
          <div className='text-center'>
            <h1>Contact</h1>
            <a href='https://wa.me/27795039740'>
              <section className='contact-button'>
                <img
                  alt="whatsapp"
                  src="/icon/whatsapp.svg"
                  height="40px"
                />
                <h4>Whatsapp</h4>
              </section>
            </a>
            <a href='mailto:theashleyshamrock@gmail.com'>
              <section className='contact-button'>
                <img
                  alt="email"
                  src="/icon/email.svg"
                  height="40px"
                />
                <h4>Email</h4>
              </section>
            </a>
            <a href='https://www.instagram.com/shamrockpaintworks/'>
              <section className='contact-button'>
                <img
                  alt="instagram"
                  src="/icon/instagram.svg"
                  height="40px"
                />
                <h4>Instagram</h4>
              </section>
            </a>
          </div>
        </section>
      </div>
    </Element>
  )
};

export default Contact
